.home-box1-postition-wrapper {
  position: relative;
}

.home-box1-wrapper {
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(200%) blur(15px);
  height: 400px;
}

.home-box1-message-wrapper {
  float: left;
  width: 50%;
}

.home-box1-message-header {
  font-size: 2.5rem;
  font-weight: bold;
  font: Helvetica;
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 20px;
}

.home-box1-message-body {
  font-size: 1rem;
  color: rgb(100, 100, 100);
  font: Helvetica;
  width: 80%;
  margin: 0 auto;
}

.home-box1-image-wrapper {
  position: relative;
  float: right;
  width: 50%;
}

.home-box1-image {
  margin-top: 55px;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.circle-graphic-home {
  background-color: rgb(216, 242, 172, 0.85);
  position: absolute;
  top: 0px;
  left: 50px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  z-index: -1;
  margin-left: -150px;
  margin-bottom: -150px;
}

.circle-graphic-home-1 {
  background-color: rgb(216, 242, 172, 0.85);
  position: absolute;
  bottom: 0px;
  right: 50px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: -1;
  margin-left: -100px;
  margin-bottom: 0px;
}

.circle-graphic-home-2 {
  background-color: rgb(216, 242, 172, 0.85);
  position: absolute;
  bottom: 200px;
  right: 45%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: -1;
  margin-left: -100px;
  margin-bottom: 0px;
}

.home-box2-wrapper {
}

.home-box2-row-wrapper {
  overflow-y: hidden;
  margin: 60px 0px;
}

.home-box2-element {
  float: left;
  width: 49%;
  min-height: 400px;
}

.home-box2-1 {
  float: right;
}

.home-box2-text-wrapper {
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.home-box2-padded-wrap {
  padding: 20px;
  box-sizing: border-box;
}

.home-box2-image-wrapper {
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home-contact-position-wrapper {
  position: relative;
}

.home-contact-background {
  margin-top: 40px;
  padding: 30px 0px;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(200%) blur(15px);
}

.home-contact-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.circle-graphic-home-3 {
  background-color: rgb(216, 242, 172, 0.85);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  z-index: -1;
  margin-left: -50px;
  margin-top: -80px;
}

.circle-graphic-home-4 {
  background-color: rgb(216, 242, 172, 0.85);
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: -1;
  margin-right: -50px;
  margin-bottom: -50px;
}

@media (width < 870px) {
  .home-box1-message-header {
    font-size: 2.5rem;
    font-weight: bold;
    font: Helvetica;
    width: 80%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

@media (width < 640px) {
  .home-box1-image-wrapper {
    width: 100%;
    float: none;
  }

  .home-box1-image {
    margin-top: 25px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .home-box1-wrapper {
    height: auto;
  }

  .home-box1-message-wrapper {
    float: none;
    width: 100%;
    padding: 20px 0px;
  }

  .home-box1-message-header {
    font-size: 2.3rem;
    text-align: center;
    width: 95%;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .home-box1-message-body {
    font-size: 1rem;
    width: 85%;
    margin: 0 auto;
    text-align: center;
  }

  .circle-graphic-home {
    background-color: rgb(216, 242, 172, 0.85);
    position: absolute;
    top: 80px;
    left: 50px;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    z-index: -1;
    margin-left: -150px;
    margin-bottom: -150px;
  }

  .home-box2-element {
    float: none;
    width: 100%;
    min-height: 400px;
  }

  .home-box2-1 {
    float: none;
    width: 100%;
  }

  .home-box2-row-wrapper {
    margin: 20px 0px;
  }

  .home-box2-text-wrapper {
    padding: 0px 30px 30px 30px;
  }

  .home-contact-position-wrapper {
    margin: 60px 0px;
  }

  .circle-graphic-home-1 {
    display: none;
  }

  .circle-graphic-home-2 {
    display: none;
  }

  .circle-graphic-home-3 {
    background-color: rgb(216, 242, 172, 0.85);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: -1;
    margin-left: -50px;
    margin-top: -40px;
  }
}
