.form-wrapper {
    max-width: 900px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 70px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 6px rgb(0, 0, 0, 0.3);
    background-color: rgb(255, 255, 255, 0.6);
    backdrop-filter: saturate(180%) blur(20px);
}

form h2 {
    font-weight: normal;
}

.form-element {
    margin: 5px;
    height: 35px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;

    border: none;
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.3);
    padding-left: 10px;
    box-sizing: border-box;
    width: calc(100% - 10px);
}

.form-input-name,
.form-input-phone {
    margin-bottom: 20px;
}

/* .form-header {
    font-size: 1.2em;
}

.form-label {
    font-size: 1em;
} */

/* .form-input-text {
    border: none;
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.3);
    padding-left: 10px;
    box-sizing: border-box;
    width: calc(100% - 10px);
}

.form-input-email {
    border: none;
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.3);
    padding-left: 10px;
    box-sizing: border-box;
    width: calc(100% - 10px);
} */

/* textarea { */
.form-input-textarea {
    border: none;
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.3);
    resize: none;
    height: 200px;
    padding: 10px;
    box-sizing: border-box;
    width: calc(100% - 10px);
}

.form-input-submit {
    border: none;
    height: 40px;
    width: calc(100% - 10px);
    max-width: 270px;
    background-color: rgb(216, 242, 172);
    cursor: pointer;
}

.form-input-submit:not(:disabled):hover {
    box-shadow: 2px 2px 3px rgb(0, 0, 0, 0.2);
    height: 42px;
    width: calc(100% - 8px);
    margin-top: 3px;
}

input:focus,
textarea:focus {
    /* outline: none; */
    outline: 2px solid rgb(216, 242, 172);
}

.form-element.error {
    border: 1px solid red;
}

.form-element.error::placeholder {
    color: red;
}