.news-wrapper {
  margin-top: 20px;
  padding: 0px 15px;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  /* overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth; */
}

.news-wrapper::-webkit-scrollbar {
  display: none;
}

.news-element-wrapper {
  display: inline-block;
  height: 200px;
  width: 550px;
  margin: 20px 15px;
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(15px);
  box-shadow: 0 0 15px rgb(0, 0, 0, 0.1);
  overflow: hidden;
}

.news-element-image {
  float: left;
  width: 200px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.news-element-header {
  float: right;
  width: 330px;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.news-element-body {
  float: right;
  width: 330px;
  height: 90%;
  white-space: normal;
  overflow-y: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}
.news-element-body::-webkit-scrollbar {
  display: none;
}

@media (width < 640px) {
  .news-element-wrapper {
    height: 400px;
    width: calc(100% - 80px);
    vertical-align: top;
  }

  .news-element-image {
    float: none;
    width: 100%;
    height: 180px;
  }

  .news-element-body {
    float: none;
    width: 100%;
    margin: 20px 0px;
  }

  .news-element-header {
    float: none;
    width: 100%;
    margin: 20px 0px;
  }
}
