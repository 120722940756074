body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}

.body-wrapper {
  padding-top: 75px;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.content-wrapper {
  width: 75%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

@media (width <= 1300px) {
  div.content-wrapper {
    width: 75%;
  }
}

@media (width <= 900px) {
  div.content-wrapper {
    width: 100%;
  }
}

div.body-wrapper {
  margin-top: 1px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 75px);
}

div.body-wrapper::-webkit-scrollbar {
  display: none;
}

.hidden {
  display: none;
}

input {
  appearance: none;
}