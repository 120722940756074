.team-0-wrapper {
  text-align: center;
}

.team-0-image {
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.3);
}

div.team-1-wrapper {
  text-align: center;
  padding: 20px 0;
}

.team-1-wrapper img {
  border: 1px solid black;
}

.team-1-wrapper p {
  text-align: left;
  padding: 0 20px;
}

.team-intro-text p {
  text-align: center;
}

div.team-1-image-wrapper {
  display: flex;
  flex-flow: row wrap;
}

div.team-1-member-wrapper {
  width: calc(33% - 20px);
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.3);
  border-radius: 5px;
}

div.team-1-member-portrait {
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin: 0 auto;
}

div.team-1-member-text {
  text-align: center;
  width: 100%;
}

p.team-1-member-name {
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}

p.team-1-member-title {
  color: rgba(110, 110, 110);
  font-size: 0.9em;
  text-align: center;
}

p.team-1-member-task {
  font-weight: lighter;
  font-size: 0.9em;
  text-align: center;
}

@media (width <= 600px) {
  div.team-1-member-wrapper {
    width: 100%;
  }
}

div.team-2-wrapper {
  overflow: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

div.team-2-content-wrapper {
  margin: 30px 20%;
  padding: 10px 10px;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  text-align: center;
  border-radius: 5px;
}

@media (width < 480px) {
  div.team-2-content-wrapper {
    margin: 15px;
  }
}
