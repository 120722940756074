div.leistungen-wrapper {
  min-height: calc(100vh - 150px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;
  max-width: 1000px;
  margin: 0 auto;
}

div.leistungen-header-wrapper {
  margin-top: 60px;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: black;
  font-family: "Times New Roman", Times, serif;
}

.leistungen-subTitle-wrapper {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  color: rgb(150, 150, 150);
}

div.leistungen-list-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
}

div.leistungen-element-wrapper {
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px;
  width: calc(50% - 40px);
  height: 250px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.3);
  z-index: 1;
  overflow: hidden;
}

.leistungen-element-inner-wrapper {
  /* background-color: green; */
  width: 340px;
  margin: 0 auto;
}

div.leistungen-image-wrapper {
  display: inline-block;
  vertical-align: top;
  /* background-color: red; */
}

.leistungen-image {
  display: inline-block;
  width: 60px;
}

.leistungen-description-wrapper {
  display: inline-block;
  width: 250px;
  margin-left: 20px;
  /* background-color: yellow; */
}

div.leistungen-element-header {
  width: 100%;
  height: 40px;
  text-align: left;
  font-size: 26px;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 10px;
}

div.leistungen-element-subservice {
  width: 100%;
  text-align: left;
  color: rgb(120, 120, 120);
  margin-top: 5px;
  display: inline-block;
}

.circle-graphic {
  background-color: rgb(216, 242, 172, 0.85);
  position: fixed;
  bottom: 0px;
  left: 50px;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  z-index: -1;
  margin-left: -350px;
  margin-bottom: -350px;
}

@media (width < 640px) {
  div.leistungen-element-wrapper {
    margin: 10px 10px;
    width: calc(50% - 20px);
  }

  div.leistungen-element-wrapper {
    width: calc(100% - 20px);
  }

  .circle-graphic {
    position: fixed;
    bottom: 60px;
    left: 0px;
    width: 600px;
    height: 600px;
    margin-left: -300px;
    margin-bottom: -300px;
  }
}

@media (width < 480px) {
  div.leistungen-element-wrapper {
    margin: 10px 10px;
    width: calc(100% - 20px);
  }
}
