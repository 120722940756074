.contact-header {
  font-size: 1em;
  font-weight: bold;
}

.navigation-link {
  color: rgb(216, 242, 172);
  color: blue;
}

.navigation-arrow {
  width: 12px;
  box-sizing: border-box;
  margin-left: 5px;
  margin-bottom: -1px;
  color: blue;
}


.contact-vacation {
  white-space: normal;
  line-height: normal;
}

.contact-table {
  text-align: left;
  /* margin: 0 auto; */
}
.contact-table td {
  padding-right: 10px;
}

div.contact-list-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.contact-list-wrapper p {
  line-height: 0.6em;
}

div.contact-element-wrapper {
  width: 30%;
}

div.contact-element-wrapper-middle {
  width: 40%;
}

@media (width <= 1000px) {
  div.contact-element-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  div.contact-element-wrapper-middle {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .contact-table {
    margin: 0 auto;
  }
}

.contact-notdienst {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}