.kiefer-sub-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.kiefer-sub-image-wrapper {
  width: 50%;
  display: inline-block;
  height: calc(50vh);
}

.kiefer-sub-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  margin: 0 auto;
  border-radius: 5px;
  margin: 40px;
}

.kiefer-sub-text-wrapper {
  width: 50%;
  height: calc(100vh - 100px);
  display: inline-block;
  vertical-align: top;
  /* background-color: turquoise; */
}

.kiefer-sub-text-surface {
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  /* box-shadow: 0 0 6px rgb(0, 0, 0, 0.3); */
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  margin: 40px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.kiefer-sub-text-header {
  font-family: "Times New Roman", Times, serif;
  font-size: 40px;
  margin-bottom: 25px;
}

.kiefer-sub-text-body {
  width: 100%;
  font: Helvetica;
}

.circle-graphic-kiefer-sub {
  background-color: rgb(216, 242, 172, 0.85);
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  z-index: -1;
  margin-right: -300px;
  margin-bottom: -300px;
}

@media (width < 640px) {
  .kiefer-sub-image-wrapper {
    width: 100%;
    height: 100vw;
  }

  .kiefer-sub-text-wrapper {
    width: 100%;
    height: auto;
  }

  .kiefer-sub-image {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
  }

  .kiefer-sub-text-surface {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
  }
}
