div.header-wrapper {
  width: 100%;
  height: 75px;
  font-size: 1em;
  line-height: 3;
  letter-spacing: -0.01em;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  top: 0px;
}

div.header-inner-wrapper {
  height: 100%;
  vertical-align: middle;
}

div.header-brand-wrapper {
  float: left;
  margin: 0 10px;
  height: 100%;
}

div.header-brand-text {
  float: left;
  cursor: pointer;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

img.header-brand-logo {
  float: left;
  max-width: 40px;
  cursor: pointer;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-menu-btn {
  display: block;
  height: 40px;
  width: 120px;
  border-radius: 5px;
  background-color: rgb(216, 242, 172);
  text-align: center;
  float: right;
  position: relative;
  cursor: pointer;
  margin-top: 19px;
  margin-right: 20px;
  text-decoration: none;
  color: black;
}

.anamnese-menu-btn {
  margin-right: 0px;
}

.header-menu-item .anamnese-menu-btn {
  margin: 0 auto;
  float: none;
}

.header-menu-btn-text {
  margin-top: -2px;
  font-size: 14px;
}

img.instagram-image {
  width: 35px;
  height: 35px;
  cursor: pointer;
  padding-top: 22px;
  /* background-color: white; */
}

div.header-menu-button {
  float: right;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menuicon {
  height: 50px;
  width: 50px;
  position: absolute;
  transition: all 0.6s ease-out;
}

.menuicon.open .menuicon-top {
  transform: rotate(45deg) translate(2px, 2px);
}

.menuicon.open .menuicon-bottom {
  transform: rotate(-45deg) translate(3px, -4px);
}

.menuicon-label {
  display: block;
  position: absolute;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.menuicon-wrap-1 {
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  position: absolute;
}

.menuicon-top {
  display: block;
  width: 16px;
  height: 1px;
  background: rgb(25, 25, 25);
  margin-left: 7px;
  margin-top: 11px;
  transition: all 0.3s ease-out;
}

.menuicon-wrap-2 {
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  position: absolute;
}

.menuicon-bottom {
  display: block;
  width: 16px;
  height: 1px;
  background: rgb(25, 25, 25);
  margin-left: 7px;
  margin-top: 19px;
  transition: all 0.3s ease-out;
}

div.header-menu-wrapper {
  height: 100%;
  width: 100%;
}

.header-menu-extended {
  display: flex;
  justify-content: space-between;
}

div.header-menu-item {
  float: left;
  cursor: pointer;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 14px;
}

.header-menu-item-active {
  color: green;
}

@media (width <=900px) {
  div.header-menu-wrapper {
    width: 100%;
    height: auto;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  }

  div.header-menu-item {
    width: 100%;
    margin-right: 0;
    text-align: center;
    -ms-transform: initial;
    transform: initial;
  }

  img.instagram-image {
    width: 100%;
    margin-right: 0;
    text-align: center;
    -ms-transform: initial;
    transform: initial;
    padding-bottom: 15px;
  }
}

@media (width <=370px) {
  .header-menu-btn {
    height: 40px;
    width: 110px;
    margin-top: 19px;
    margin-right: 5px;
  }
}