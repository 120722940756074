.google-map {
  width: 100%;
  height: 80vh;
  margin-top: 40px;
}

.pin {
  display: flex;
  align-items: center;
  height: 60px;
  width: 120px;
  color: rgb(216, 242, 172);
  transform: translateY(-60px) translateX(-60px);
}

.pin-icon {
  height: 60px;
  width: 120px;
}

.pin-text {
  font-size: 1.3em;
}

@media (width < 840px) {
  .google-map {
    height: 60vh;
  }
}
