
div.praxis-background-wrapper
{
    padding-top: 60vh;
}

div.praxis-wrapper
{
    background-color: white;
    min-height: calc(40vh - 150px);
}

div.praxis-carousel-wrapper
{
    height: 320px;
    width: auto;
    padding-left: 40px;

    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    scroll-behavior: smooth;

    -webkit-mask-image: linear-gradient( to right, transparent 0%, black 10%, black 90%, transparent 100% );
    mask-image: linear-gradient( to right, transparent 0%, black 10%, black 90%, transparent 100% );
}

.praxis-carousel-wrapper::-webkit-scrollbar
{
    display: none;
}

div.praxis-carousel-image
{   
    width: 300px;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    margin-right: 50px;

    float: none;
    display: inline-block;
}