
div.mobilemenu-wrapper {
    position: fixed;
    bottom: 28px;
    right: 28px;
    width: 56px;
    height: 56px;

    background-color: #DDF4B9;
    border-radius: 50%;
    box-shadow: 0 0 6px rgb(0,0,0,0.3);

    text-align: center;
    cursor: pointer;

    z-index: 99;
}

.call-icon {
    position: relative;
    top: 25%;
    color: white;
    width: 50%;
    height: auto;
}