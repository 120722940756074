.kiefer-hero-wrapper {
  margin: 40px 0px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  height: 350px;
}

.kiefer-main-text-wrapper {
  width: calc(50% - 20px);
  margin-top: 50px;
  margin-right: 20px;
}

.kiefer-main-text-header {
  font-family: "Times New Roman", Times, serif;
  font-size: 2.5rem;
  margin-bottom: 25px;
}

.kiefer-main-text-body {
  font: Helvetica;
}

.kiefer-main-text-body p {
  margin: 0;
}

.kiefer-main-image-wrapper {
  width: 50%;
  background-color: #ddf4b9;
  text-align: center;
  position: relative;

  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url("../Assets/blob.svg");
}

.kiefer-main-image {
  height: 100%;
  max-height: 290px;
  width: auto;
  margin-top: 80px;
  margin-bottom: -10px;
}

.kiefer-leistungen-element-wrapper {
  position: relative;
  cursor: pointer;
  height: 250px;
  width: calc(50% - 40px);
  margin: 20px 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
}

.kiefer-leistungen-image-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 50%;
  height: 100%;
}

.kiefer-leistungen-description-wrapper {
  width: calc(50% - 20px);
  margin-left: 20px;
  position: relative;
}

.kiefer-leistungen-header {
  text-decoration: none;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
}

.kiefer-leistungen-body {
  color: rgb(131, 131, 131);
  font-size: 14px;
}

.kiefer-leistungen-readmore {
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  bottom: 5px;
  left: 0px;
}

.more-link-svg {
  height: 12px;
  margin-bottom: -2px;
  margin-left: 5px;
}

.circle-graphic-kiefer {
  background-color: rgb(216, 242, 172, 0.85);
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  z-index: -1;
  margin-left: -250px;
  margin-bottom: -300px;
}

@media (width < 1000px) {
  .kiefer-main-image-wrapper {
    width: 55%;
  }

  .kiefer-main-text-wrapper {
    width: 45%;
    margin-right: 0px;
  }

  .kiefer-hero-wrapper {
    margin: 20px 0px;
  }
}

@media (width < 900px) {
  .kiefer-main-image-wrapper {
    width: 50%;
  }

  .kiefer-main-text-wrapper {
    width: calc(50% - 20px);
    margin-right: 20px;
  }
}

@media (width < 800px) {
  .kiefer-hero-wrapper {
    height: 420px;
  }
}

@media (width < 700px) {
  .kiefer-leistungen-element-wrapper {
    width: calc(100% - 40px);
    max-width: 600px;
    margin: 20px auto;
  }
}

@media (width < 640px) {
  .kiefer-main-image-wrapper {
    width: 100%;
  }

  .kiefer-main-text-wrapper {
    width: 100%;
    margin-right: 0px;
    margin-top: 0px;
    text-align: center;
  }

  .kiefer-hero-wrapper {
    height: auto;
  }

  .kiefer-leistungen-image-wrapper {
    border-radius: 5px;
    width: 100%;
    height: calc(100vw - 140px);
    margin-bottom: 20px;
  }

  .kiefer-leistungen-description-wrapper {
    width: calc(100% - 20px);
    margin-left: 0px;
  }

  .kiefer-leistungen-element-wrapper {
    height: auto;
  }

  .kiefer-leistungen-readmore {
    position: relative;
    margin-top: 20px;
  }

  .circle-graphic-kiefer {
    bottom: 0px;
    left: 0px;
    width: 500px;
    height: 500px;
    margin-left: -300px;
    margin-bottom: -350px;
  }
}

@media (470px < width < 640px) {
  .kiefer-main-image {
    margin-bottom: -10px;
  }
}

@media (width < 400px) {
  .kiefer-main-text-wrapper {
    width: 100%;
    margin-right: 0px;
    margin-top: -30px;
  }

  .kiefer-hero-wrapper {
    margin: 0px 0px;
  }
}
