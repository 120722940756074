.popup-wrapper {
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 350px;
  padding: 20px 0px 20px 20px;
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-flow: row wrap;
}

.popup-header {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}

.popup-text-wrapper {
  font-size: 0.9rem;
  width: calc(100% - 60px);
  height: 75px;
}

.popup-link {
  color: blue;
  font-size: 0.9rem;
  width: 100%;
}

.popup-link:hover {
  text-decoration: underline;
}

.popup-button {
  width: 40px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
}

.popup-button-image {
  height: 16px;
  margin-top: 12px;
  margin-left: 12px;
}

.popup-link {
  cursor: pointer;
}

.popup-link:hover {
  color: grey;
}

@media (width < 640px) {
  .popup-wrapper {
    right: 10px;
    bottom: 10px;
    width: calc(100% - 40px);
  }
}
