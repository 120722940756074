.datenschutz-wrapper blockquote {
  line-height: 0.2em;
}

.datenschutz-wrapper {
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  white-space: normal;
  box-sizing: border-box;
}

@media (width < 640px) {
  .datenschutz-wrapper {
    margin: 0px 20px;
    width: 90%;
  }
}
